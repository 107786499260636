<!--
   /**
      * newsList.vue
      * @module {src/modules/public}
      * @desc 前端新闻列表
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :span="24" style="margin-bottom: 20px">
                <p style="font-size: 24px; color: #333333; padding-top: 10px; padding-bottom: 10px;text-align: center;border-bottom: 1px solid #dedede">{{this.$route.name}}</p>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(item,index) in dataList" :key="index" style="margin-bottom: 10px;cursor: pointer" @click.native="goToNews(item.news_code)">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p style="font-size: 20px; color: #333333; padding-top: 10px; padding-bottom: 10px">{{item.title}}</p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">{{item.digest}}</p>
                      </el-col>
                      <el-col :span="24">
                        <p style="font-size: 12px; color: #999; padding-top: 10px; padding-bottom: 10px;">内容来源：{{item.org_name}}<span style="float: right">发布时间：{{item.create_time}}</span></p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>

              </el-col>

            </el-row>
            <el-row :gutter="10" class="margin_b_20">
              <el-col :span="24">
                <div class="m-t-16 a-c">
                  <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                                 :current-page="pageIndex" :page-sizes="[10]" :page-size="pageSize"
                                 :total="totalPage" layout="total, sizes, prev, pager, next">
                  </el-pagination>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
      pageSize: 10, // 每页的数据条数
      skip: 0, // 起始
      limit: 1,
      id: 0,
      itemList: {},
      dataList: [
        {
          create_by: 'admin',
          create_time: '2021-01-21 16:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news10.png',
          digest: '光阴似箭，日月如梭，眼看着春节还有半个多月就即将来临，富融运营开进物业社区，举行多场年货节活动。本次年货节吸引了8家企业数十款商品参加，有百果园的招牌红芭蕾草莓、徐福记礼包、中垦礼包、满婷香皂、粤好茶等等，选择更多，价格更低！',
          status: 1,
          news_code: 'N1577950571000',
          title: '富融牛年运营第一波  社区年货节热闹开锣'
        },
        {
          create_by: 'admin',
          create_time: '2021-01-21 10:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news09.png',
          digest: '长期以来，每当提起物业一词，“门槛低”、“低端行业”的声音不绝于耳。大多数人认为物业行业门槛低、收入低、工作技术含量不高，所以一直都将其视作为低端服务行业，甚至是一个“低端无服务”行业。',
          status: 1,
          news_code: 'N1577950570999',
          title: '物业告别"低端"印象，看看这些智慧社区场景有多酷?'
        },
        {
          create_by: 'admin',
          create_time: '2021-01-19 10:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news08.jpg',
          digest: '相信不少业主看到这个问题的时候都会气愤说自家的物业什么都不会做，只会收物业费，更把公共部分面积租出去谋利，而得到的钱也不给业主，反而自己私吞了...最后就会说取消物业了。',
          status: 1,
          news_code: 'PT1577950570998',
          title: '作为业主，您知道自家的物业在干什么吗？'
        },
        {
          create_by: 'admin',
          create_time: '2021-01-14 11:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news07.png',
          digest: '全国第一家物业公司在1993年成立，这个行业经历了将近28个年头，但至今所得的名声并不是太好，各地都有取消物业的呼声，业主们感觉付出的物业费等支出与得到的服务相差太远，交了冤枉钱，更有物业利用公共收益敛财，通过停车位、电梯广告等方式增加收入，令业主觉得自身的利益受到侵占，双方均处于不太友好的状态。',
          status: 1,
          news_code: 'PT1577950570997',
          title: '物业公司如何实现“物业服务+生活服务”'
        },
        {
          create_by: 'admin',
          create_time: '2021-01-12 12:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news06.png',
          digest: '刚进入新的一年，在A股市场中，招商积余等物业股涨停，南都物业、新大正等物业相关股票上涨幅度也超过7%，而在港股物业企业中，共有20余家物业股涨幅在10%之上。此番集体上涨，包含了对政策暖风的快速体现，为物业未来发展提供理想的空间。',
          status: 1,
          news_code: 'PT1577950570996',
          title: '政策红利出来了——中小物业该如何把握呢？'
        },
        {
          create_by: 'admin',
          create_time: '2021-01-07 12:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news05.png',
          digest: '《民法典》明确规定，物业利用业主公共部分产生的收益，首先扣除合理的运营成本，余下的部分属于业主所有，建筑物及其附属设施的收益，将按照约定分配；若没有约定或约定不明确的情况，将按业主专有部分面积的占比确定，比如电梯广告、门禁广告、公共区域的停车位等等，这些收益以后要分配给业主处理。',
          status: 1,
          news_code: 'PT1577950570995',
          title: '《民法典》已经正式实施，有几点物业公司要注意了'
        },
        {
          create_by: 'admin',
          create_time: '2020-12-30 10:00',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news04.png',
          digest: '随着物联网技术的迅猛发展，其让管理实现“可视、可控、可管”的优势，在各种领域中的应用越来越广泛，尤其在物业项目中，智能社区、智能园区、智能写字楼、智能酒店甚至智能军营，都在基于物联网的基础上，得到了极大的发展。',
          status: 1,
          news_code: 'PT1577950570994',
          title: '富融参加国际物业展，现场产品推介获认可'
        },
        {
          create_by: 'admin',
          create_time: '2020-10-29 12:05',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/news03.jpg',
          digest: '庐山，因苏轼的《题西林壁》而闻名天下，群山围绕，绿意盎然。富融物创，因优秀的服务，已经进驻江西庐山新城！',
          status: 1,
          news_code: 'PT1577950570993',
          title: '多样复杂的物业项目 一样精彩的富融物创'
        },
        {
          create_by: 'admin',
          create_time: '2020-09-01 15:36',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          // "img": "/assets/about.jpg",
          img: '/static/images/cases01-1.jpg',
          digest: '经过3年多的积累，富融于2019年推出物业增值创新平台（简称：富融物创），今年3月，与在佛山经营超过20年的恒福物业达成合作，至今已经完成恒福物业旗下12个项目（其中包括8个物业项目）、300多名员工以及接近10000名业主的数据导入。',
          status: 1,
          news_code: 'PT1577950570992',
          title: '富融物创再添案例，恒福物业全面进驻'
        },
        {
          create_by: 'admin',
          create_time: '2020-07-29 13:16',
          org_code: 'O1576131685411',
          org_name: '广州富融信息技术有限公司',
          img: '/static/images/news02.png',
          digest: '随着房地产的发展，物业小区业主多了，物业服务需求随之暴增，由此引发的业主和物管之间的矛盾时有发生，如何提升物业服务让业主满意成为物管公司的痛中之痛。物管与业主之间有两个最大的关联点，一个是物业收费，一个是物业服务，如何为业主提供优质的物业服务非常关键，据富融SaaS物创平台的数据统计，目前物业服务使用率最高的是维修，占所有物业服务中的48%！',
          status: 1,
          news_code: 'PT1577950570991',
          title: '工单管理太凌乱，富融助你来修剪'
        }
      ],

      pageIndex: 1,
      totalPage: 10,
      dataListLoading: false
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
    },
    goToNews (news_code) {
      this.$router.push(
        {
          path: '/newsDetails',
          query: {
            news_code: news_code
          }
        }
      )
    },
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
